import { Box, Typography } from "@mui/material";

function HomePage() {

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "Center",
            flexDirection: "column"
        }}>
            <Box sx={{
                margin: "auto",
                marginTop: "30px",
                marginBottom: "30px"
            }}>
                <Typography variant="h5">Welcome to the Kacper Airsoft Experience!</Typography>
            </Box>

            <Box
                component="img"
                sx={{
                    width: 300,
                    margin: "auto"
                }}
                alt="Mr Airsoft Experiene Himself"
                src="/kacper.jpg"
            />

            <Box sx={{
                margin: "auto",
                marginTop: "30px",
                marginBottom: "30px"
            }}>
                <Typography variant="b1" paragraph sx={{
                    textAlign: "center"
                }}>The Man, The Myth, The Legend.</Typography>
                <Typography variant="b1" display="block" paragraph>Why not take a trip over to our Prediction page and see what the man himself is up to in the rapidly moving (unlike him) world of Airsoft.</Typography>
            </Box>

        </Box>

    )

}

export default HomePage;