import { AppBar, Box, Button, Containers, IconButton, Toolbar, Typography, Menu, MenuItem } from "@mui/material";
import { Link as RouterLink, Navigate } from "react-router-dom";

function Navigation() {

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "Center"
        }}>
            <AppBar position="static">
                <Toolbar>
                    <Box sx={{
                        margin: "auto"
                    }}>
                        <Button color="inherit" component={RouterLink} to={"/"}>
                            Home
                        </Button>
                        <Button color="inherit" component={RouterLink} to={"/predictions"}>
                            Predictions
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>

    )

}

export default Navigation;