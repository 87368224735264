import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import Navigation from "../components/Navigation/Navigation";

function Banner() {

    return (
        <Box>
            <Navigation />
            <Outlet />
        </Box>

    )

}

export default Banner;