import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Banner from "./pages/Banner";
import ErrorPage from "./pages/ErrorPage"; 
import HomePage from "./pages/HomePage";
import About from "./pages/About";
import Predictors from "./pages/Predictors";
import OpticsLifecycle from "./pages/OpticsLifecycle";


const router = createBrowserRouter(
    [
        {
            path: "/", element: <Banner />, errorElement: <ErrorPage />, children: [
                { path: "", element: <HomePage />  },
                { path: "/predictions", element: <Predictors /> },
                { path: "/opticslifecycle", element: <OpticsLifecycle /> },
                { path: "/about", element: <About /> }


            ]
        }
    ]
)



function App() {
    return <RouterProvider router={router} />;
}

export default App;