import { useState } from "react";
import { Button, Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import usePredictor from "../hooks/usePredictor";

function Predictors() {

    const [loadingText, setLoadingText] = useState(null);
    const [result, setResult] = useState(null);
    const [buttonText, setButtonText] = useState("Which Way is the window blowing?");
    const [stingCounter, setStingCounter] = useState(0);



    const handleClick = () => {
        setLoadingText(null);
        setResult(null);
        setStingCounter(0);

        setLoadingText("Calculating...");

        setTimeout(() => {
            setLoadingText("Checking Youtube...");
        }, 1000)

        setTimeout(() => {
            setLoadingText("Browsing Airsoft Discords...");
        }, 2000)

        setTimeout(() => {
            setLoadingText("Ordering from Daves Custom Airsoft...");
        }, 3000)

        setTimeout(() => {
            setLoadingText("Chasing order...");
        }, 4000)

        setTimeout(() => {
            setLoadingText("Still Chasing Order...");
        }, 5000)

        setTimeout(() => {
            setLoadingText("Jesus Fucking Christ Dave's Custom Airsoft...");
        }, 6000)

        setTimeout(() => {
            setLoadingText("Buying BB's...");
        }, 7000)

        setTimeout(() => {
            setLoadingText("Setting Hop...");
        }, 8000)

        setTimeout(() => {
            setLoadingText("Rebuilding RIF...");
        }, 9000)

        setTimeout(() => {
            setLoadingText("Re-Rebuilding RIF...");
        }, 10000)

        setTimeout(() => {

            const x = Math.floor((Math.random() * 2) + 1);
            setLoadingText(null);

            if (x === 1) {
                setResult("MWS GBBR")
            } else {
                setResult("MTW HPA")
            }

            setButtonText('Try another prediction?')

        }, 11000)

        setTimeout(() => {
            setStingCounter(1)
        }, 14000)

        setTimeout(() => {
            setStingCounter(2)
        }, 18000)

        setTimeout(() => {
            setStingCounter(3)
        }, 25000)




    }

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
        }}>
            <Button variant="contained" onClick={handleClick}
                sx={{
                    margin: "auto",
                    maxWiwdth: "30%",
                    width: "fit-content",
                    marginTop: "40px",
                    marginBottom: "40px"
                }}
            >{buttonText}</Button>

            {loadingText && <Typography variant="h3" sx={{
                textAlign: "center"
            }}>{loadingText}</Typography>}

            {result &&
                <Box>
                    <Card sx={{
                        maxWidth: "400px",
                        margin: "auto"
                    }}>
                        <CardMedia sx={{

                            height: "300px"
                        }}
                            image="/kacpershred.jpg"
                            title="Kacper Shredding"

                        />
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div" sx={{
                                textAlign: "center"
                            }}>{result}</Typography>

                            <Typography variant="body1" color="text.secondary" sx={{
                                textAlign: "center"
                            }}>
                                Kacper is going to be using the {result}
                            </Typography>


                            {stingCounter > 0 && <Typography variant="body1" color="text.secondary" sx={{
                                textAlign: "center"
                            }}>
                                for the moment...
                            </Typography>}

                            {stingCounter > 1 && <Typography variant="body1" color="text.secondary" sx={{
                                textAlign: "center"
                            }}>
                                ...probably...
                            </Typography>}

                            {stingCounter > 2 && <Typography variant="body1" color="text.secondary" sx={{
                                textAlign: "center"
                            }}>
                                ...i mean, it really depends if...
                            </Typography>}
                            
                        </CardContent>


                    </Card>
                </Box>}

        </Box>
    )

}

export default Predictors;